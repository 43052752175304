import { logout } from '@/store/auth/authSlice';
import { AppDispatch } from '@/store/store';
import { prefetchOrderForm, prefetchPreferences, resetApiState } from '@/store/emptyApi';
import { resetPreferencesState } from '@/store/preferences/preferencesSlice';
import { restoreOrderState } from '@/store/order/orderSlice';

export const authLogout = () => (dispatch: AppDispatch) => {
  dispatch(resetPreferencesState());
  dispatch(restoreOrderState());
  dispatch(logout());
  dispatch(resetApiState());
  dispatch(prefetchPreferences())
  dispatch(prefetchOrderForm())
};
