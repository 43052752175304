import { IPreferences } from '@/types/common/preferences';
import { camelCase, range, upperFirst } from 'lodash';
import packageJSON from '../../package.json';

export const X_HOST: string = process.env.REACT_APP_X_HOST || window.location.host.replace(/^app\./, '');

// Default preferences
export const INITIAL_PREFERENCES: IPreferences = {
  assets: {
    logo: `https://${X_HOST}/static/img/logo.svg`,
    favicon: '',
    payment: {
      success: 'https://studycrumb.com/img/payment/success.png',
      error: 'https://studycrumb.com/img/payment/error.png',
    },
  },
  theme: {},
  site: {
    name: `${upperFirst(camelCase(X_HOST))} App`,
    url: X_HOST,
  },
}

// Sync with <title> from index.html
export const SITE_NAME = `Customer Dashboard • ${process.env.REACT_APP_SITENAME || 'App'}`;

// Track app version
export const APP_VERSION: string = packageJSON.version; // show version

// API base url
export const API_BASE_URL: string = process.env.REACT_APP_API_URL || 'API_BASE_URL';

// DATE ISO FORMAT
export const DATE_FORMAT_DEADLINE_PICKER = 'MMM DD, YYYY [at] h A';
export const DATE_FORMAT_DEFAULT = 'MMMM D, YYYY, h:mm:ss A';
export const DATE_FORMAT_ISO = 'YYYY-MM-DD HH:mm:ss';
export const SERVER_TIME_FORMAT = 'HH:mm:ss';
export const TIME_FORMAT = 'h:mm A';

// PAGINATION
export const PAGE_SIZE_OPTIONS = range(1, 101);

// FILES

export const ALLOWED_IMAGES = ['.jpeg', '.png', '.gif', '.jpg'];

export const ALLOWED_EXTENSIONS = [
  ...ALLOWED_IMAGES,
  '.txt',
  '.zip',
  '.doc',
  '.docx',
  '.xls',
  '.xlsx',
  '.rtf',
  '.ppt',
  '.pptx',
  '.csv',
  '.pdf',
  '.odt',
  '.html',
];

export const UPLOAD_ACCEPT_FILES = ALLOWED_EXTENSIONS.join(',');
export const UPLOAD_IMAGE_FILES = ALLOWED_IMAGES.join(',');

// !!! Sync with _cssVariables.less
export const THEME_COLORS: string[] = [
  'brand',
  'brand-content',
  'cta-button-content',
  'text-main',
  'text-heading',
  'text-alt',
  'text-input',
  'text-muted',
  'site-bg',
  'header-bg',
  'widget-bg',
  'widget-bg-alt',
  'disabled-bg',
  'muted',
  'link',
  'success',
  'warning',
  'processing',
  'unable',
  'danger',
  'error',
  'untouched-status',
  'claimed-status',
  'draft-status',
  'not-imposed-status',
  'pending-status',
  'in-work-status',
  'up-for-grabs-status',
  'completed-status',
  'submitted-status',
  'ready-status',
  'resolved-status',
  'aborted-status',
  'removed-status',
  'unable-to-commence-status',
  'unable-to-proceed-status',
  'withdrawn-status',
  'declined-status',
  'disputed-status',
  'canceled-status',
  'customer',
  'writer',
  'support',
];

export const isMac = !!navigator.platform.match(/mac/gi);

export const USER_DATA_KEY: 'customer' | 'writer' | 'author' = 'customer'; // for messenger
