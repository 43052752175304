import { persistor, store } from '@/store/store';
import React from 'react';
import { createRoot } from 'react-dom/client';
import 'react-phone-input-2/lib/style.css';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import App from './app/App';
import './i18n';

// Styles
import './styles/global/antd/theme.less';
import './styles/global/index.less';

const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </PersistGate>
  </Provider>,
);

/**
 * Handle all annoying antd deprecation errors
 */
const logError = console.error; //
console.error = (...args) => {
  if (String(args?.[0]).match(/Warning:\s\[antd:/)) {
    const payloadMessage = String(args[0]).split('\n')[0];
    console.log('👋', payloadMessage);
  } else {
    logError(...args);
  }
};
