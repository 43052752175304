import ReactJson, { ReactJsonViewProps } from 'react-json-view';
import s from './Debug.module.less';

type Props = {
  data: object | undefined;
  title?: string;
} & Omit<ReactJsonViewProps, 'src'>;

export const Debug = ({ data, title, ...reactJsonProps }: Props) => {
  if (!data || !window.location.origin.match(/local|stage/)) return null;
  return (
    <div className={s.wrapper}>
      {title && <h2>{title}</h2>}
      <ReactJson src={data} collapsed={true} displayDataTypes={false} {...reactJsonProps} />
    </div>
  );
};
