import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          common: {
            or: 'or',
          },
          header: {
            orderBtn: 'New order',
            signIn: 'Sign in',
          },
          login: {
            email: {
              label: 'Your email',
              placeholder: 'Type in your email',
            },
            password: {
              label: 'Password',
              placeholder: 'Type in your password',
            },
            signIn: 'Sign in',
            forgot: 'Forgot password?',
            order: 'Sign up with order',
            register: 'Register',
          },
          register: {
            email: {
              label: 'Your email',
              placeholder: 'Type in your email',
            },
            password: {
              label: 'Create password',
              placeholder: 'Create your password',
            },
            confirm: {
              label: 'Confirm password',
              placeholder: 'Type in your password again',
            },
            signUp: 'Sign up',
            signIn: 'Sign in',
          },
          order: {
            form: {
              heading: 'Make a New Order',
            },
          },
          forgotPassword: {
            backToLogin: 'Back to login',
            title:
              'You may need to check the junk mail folder or unlock the address: <strong>support@{{host}}</strong>',
            enterEmail: 'Enter your email address to reset your password.',
            checkEmail: 'Check your email for a new password.',
            sendEmail: 'Send email',
            checkSpam: " Don't forget to check the spam/junk folder or unlock <strong>support@{{host}}</strong>",
            failed: 'Failed to send password recovery link to your email. Try again later.',
          },
          resetPassword: {
            save: 'Save',
            success: 'Password successfully updated!',
            newPassword: 'New password',
            repeatPassword: 'Repeat password',
            passwordsMismatch: 'Passwords do not match',
            recoveryLinkNotValid: 'Your password recovery link is not valid or expired',
          },
        },
      },
    },
  });

export default i18n;
