import React, { lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

const Auth = lazy(() => import('@feature/auth/Auth'));
const AuthLogin = lazy(() => import('@feature/auth/AuthLogin'));
const AuthRegister = lazy(() => import('@feature/auth/AuthRegister'));
const AuthForgotPassword = lazy(() => import('@feature/auth/AuthForgotPassword'));
const AuthResetPassword = lazy(() => import('@feature/auth/AuthResetPassword'));

export const GuestRoutes = () => {
  return (
    <Routes>
      <Route path={'/'} element={<Navigate to={'/order'} />} />
      <Route element={<Auth />}>
        <Route path={'*'} element={<AuthLogin />} />
        <Route path={'/login'} element={<AuthLogin />} />
        <Route path={'/register'} element={<AuthRegister />} />
        <Route path={'/forgot-password'} element={<AuthForgotPassword />} />
        <Route path={'/reset-password/:magicUuid'} element={<AuthResetPassword />} />
      </Route>
    </Routes>
  );
};
