import { AppGetState, AppDispatch } from '@/store/store';
import { setSearchPreferences, setPreferences, SearchDict } from '@/store/preferences/preferencesSlice';
import { SearchEngineView, views, viewConfigs } from '@/components/search/searchEngine/config';
import { omit, pick } from 'lodash';
import { ProfilePreferences } from '@/types/profile';

export const addSearchPreference =
  ({ key, value }: { key: SearchEngineView; value: SearchDict }) =>
  (dispatch: AppDispatch, getState: AppGetState) => {
    const blackListedValues = [...(viewConfigs?.find(({ id }) => id === key)?.protectedFields || [])];

    const { preferences } = getState();
    // clean up outdated views
    const updatedState = pick(preferences.search, views);

    // @ts-ignore
    updatedState[key] = { ...omit(value, blackListedValues) };

    dispatch(setSearchPreferences(updatedState));
  };

export const updatePreferences = (preferences: ProfilePreferences) => (dispatch: AppDispatch) => {
  // clean up outdated views
  const updatedState = {
    ...preferences,
    search: { ...pick(preferences?.search, views) },
  };

  dispatch(setPreferences(updatedState));
};
