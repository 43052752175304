import { Button } from '@/components/common/button/Button';
import { Container } from '@/components/common/layout/Container';
import { Flex } from '@/components/common/layout/Flex';
import { useBreakpoints } from '@/hooks/breakpoints';
import { E403SVG, E404SVG, E500SVG, EMaintenanceSVG } from '@/media/assets/errors';
import cn from 'classnames';
import { ReactNode } from 'react';

import s from './ErrorPage.module.less';

type ErrorTypes = '404' | '403' | '500' | 'Maintenance'
type Content = {
  image: ReactNode,
  heading: string | ReactNode
  text: string | ReactNode
}
const pageContent: Record<ErrorTypes, Content> = {
  404: {
    image: <E404SVG />,
    heading: <>
      Oops!
      <br />
      Sorry, we can’t find that page!
    </>,
    text: <>
      Either something went wrong or that page doesn’t exist anymore.
    </>,
  },
  403: {
    image: <E403SVG />,
    heading: <>
      You’re not permitted to see this.
    </>,
    text: <>
      The page you’re trying to access has restricted access.
      <br />
      If you feel this is mastake, please contact our support team.
    </>,
  },
  500: {
    image: <E500SVG />,
    heading: <>
      Internal server error!
    </>,
    text: <>
      The server has been deserted for a while.
      <br />
      Please be patient or try again later
    </>,
  },
  Maintenance: {
    image: <EMaintenanceSVG />,
    heading: <>
      Under Construction!
    </>,
    text: <>
      To make things right we need some time to rebuild
    </>,
  },
};

type Props = {
  type: ErrorTypes
  className?: string;
}

export const ErrorPage = ({ type, className }: Props) => {
  const { isSm } = useBreakpoints();
  const goBack = () => window.history.back();
  const { heading, text } = pageContent[type];

  return (
    <div className={cn(s.wrapper, className)}>
      <Container>
        <Flex
          flow={'column'}
          alignItems={'center'}
          justifyContent={'center'}
          gap={isSm ? 16 : 30}
        >
          {/* <div className={s.image}> */}
          {/*   {image} */}
          {/* </div> */}
          <Flex flow={'column'} className={s.content}>
            <div className={s.heading}>
              {heading}
            </div>
            <div className={s.text}>
              {text}
            </div>
          </Flex>
          <Button modification={'primary'} size="large" onClick={goBack}>Go Back</Button>
        </Flex>
      </Container>
    </div>
  );
};