import { IOrderSearchParams } from '@/types/order';
import { IOrderSnapshotSearchParams } from '@/types/orderSnapshots';
import { ITicketSearchParams, ITicketStatuses } from '@/types/ticket';

export const views = ['Orders', 'Tickets', 'Order Snapshots'] as const;

export type SearchEngineView = typeof views[number];

export type SearchEngineViewConfig<Params = any, D = any, S = any> = {
  id: SearchEngineView;
  defaultParams: Params;
  dateFields: D[];
  sortFields: S[];
  protectedFields: (keyof Params)[];
};

export const OrdersView: SearchEngineViewConfig<IOrderSearchParams> = {
  id: 'Orders',
  defaultParams: {
    sortBy: 'dateAdded',
    sortType: 'desc',
    size: 8,
    idAppearanceStatus: [1],
  },
  dateFields: ['dateAdded', 'dateDue'],
  sortFields: ['dateAdded', 'dateDue'],
  protectedFields: ['idAppearanceStatus'],
};

export const TicketsView: SearchEngineViewConfig<ITicketSearchParams> = {
  id: 'Tickets',
  defaultParams: {
    idStatus: [ITicketStatuses.Pending, ITicketStatuses.InWork],
    sortBy: 'dateAdded',
    sortType: 'desc',
    size: 8,
  },
  dateFields: ['dateAdded', 'dateDue'],
  sortFields: ['dateAdded', 'dateDue'],
  protectedFields: [],
};

export const OrderSnapshotsView: SearchEngineViewConfig<IOrderSnapshotSearchParams> = {
  id: 'Order Snapshots',
  defaultParams: {
    sortBy: 'dateAdded',
    sortType: 'desc',
    size: 8,
    idOrderAppearanceStatus: [1],
  },
  dateFields: ['dateAdded', 'dateDue'],
  sortFields: ['dateAdded', 'dateDue'],
  protectedFields: ['idOrderAppearanceStatus'],
};

export const viewConfigs: SearchEngineViewConfig[] = [OrdersView, TicketsView];
