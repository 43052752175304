import { IconSvg } from '@/components/common/icons/IconSvg';
import { Container } from '@/components/common/layout/Container';
import { Flex } from '@/components/common/layout/Flex';
import { HeaderMobileMenu } from '@/components/common/layout/HeaderMobileMenu';
import { AppLink } from '@/components/common/link/AppLink';
import { Logo } from '@/components/common/logo/Logo';
import { useBreakpoints } from '@/hooks/breakpoints';
import { useLazySearchTicketsQuery } from '@/api/tickets';
import { useAuth } from '@/store/auth/authHooks';
import { ITicketStatuses } from '@/types/ticket';
import { ProfilePopover } from '@feature/profile/ProfilePopover';
import cn from 'classnames';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { BadgeCounter } from '../BadgeCounter';
import s from './Header.module.less';

type Props = {
  className?: string;
};

export const Header = ({ className }: Props) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { isLoggedIn } = useAuth();

  const [getTickets, { data: tickets }] = useLazySearchTicketsQuery();

  const { isSm } = useBreakpoints();

  const lnkProps = { className: s.link, activeClassName: s.linkActive };

  const isNewOrderPage = pathname === '/order';

  useEffect(() => {
    if (isLoggedIn) {
      getTickets({ size: 1, idStatus: [ITicketStatuses.Pending, ITicketStatuses.InWork] }, true);
    }
  }, [isLoggedIn, getTickets]);

  return (
    <div className={cn(s.wrapper, className)}>
      <Container className={s.container}>
        <Logo />

        {isSm && isLoggedIn ? (
          <HeaderMobileMenu ticketsCount={tickets?.total} />
        ) : (
          <Flex gap={8} className={s.links} alignItems={'center'}>
            <AppLink to={'/order'} {...lnkProps}>
              <IconSvg iconClassName={!isNewOrderPage ? s.highlight : ''} icon={'IcoRPlus'} suffix={t('header.orderBtn')} />
            </AppLink>

            {isLoggedIn ? (
              <>
                <AppLink to={'/orders'} {...lnkProps}>
                  Orders
                </AppLink>

                <BadgeCounter count={tickets?.total} type={'alert'}>
                  <AppLink to={'/tickets'} {...lnkProps}>
                    Tickets
                  </AppLink>
                </BadgeCounter>

                <ProfilePopover />
              </>
            ) : (
              <AppLink to={'/login'} {...lnkProps}>
                <IconSvg icon={'IcoRArrowRightToBracket'} suffix={t('header.signIn')} />
              </AppLink>
            )}
          </Flex>
        )}
      </Container>
    </div>
  );
};
