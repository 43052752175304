import { useAuthLogoutMutation } from '@/api/auth';
import { useAppContext } from '@/app/contexts/AppContext';
import { Button } from '@/components/common/button/Button';
import { ErrorPage } from '@/components/common/error/ErrorPage';
import { Container } from '@/components/common/layout/Container';
import { Header } from '@/components/common/layout/Header';
import { APP_VERSION, X_HOST } from '@/config/constants';
import { useAppSelector } from '@/hooks/redux';
import { useAuth } from '@/store/auth/authHooks';
import { selectMaintenance } from '@/store/global/globalSlice';
import { camelCase, upperFirst } from 'lodash';
import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import s from './AppBaseLayout.module.less';

type Props = {
  children: ReactNode;
};

export const AppBaseLayout = ({ children }: Props) => {
  const isMaintenance = useAppSelector(selectMaintenance);
  const [requestLogout] = useAuthLogoutMutation();
  const navigate = useNavigate();
  const { isLoggedIn } = useAuth();
  const { config: { site } } = useAppContext();

  return (
    <div className={s.wrapper}>
      <Header className={s.header} />
      <div className={s.content}>
        {isMaintenance ? <ErrorPage type={'Maintenance'} /> : children}
      </div>
      <footer className={s.footer}>
        <Container>
          <div className={s.footerContent}>
            © {upperFirst(camelCase(X_HOST))} {new Date().getFullYear()}, {site.name} v{APP_VERSION}. All rights reserved.
            {isLoggedIn && (
              <Button modification={'text'} onClick={() => requestLogout().then(() => navigate('/login'))}>
                Logout
              </Button>
            )}
          </div>
        </Container>
      </footer>
    </div>
  );
};
