import { useViewCurrentUserProfileQuery } from '@/api/profile';
import { Button } from '@/components/common/button/Button';
import { useDialog } from '@/hooks/dialog';
import { ProfileDetails } from '@feature/profile/ProfileDetails';
import { Popover } from 'antd';
import cn from 'classnames';

import s from './ProfilePopover.module.less';

type Props = {
  className?: string;
};

export const ProfilePopover = ({ className }: Props) => {
  const { data: profile, isLoading } = useViewCurrentUserProfileQuery();
  const { visible, setVisible } = useDialog();

  const handleVisibleChange = (visible: boolean) => setVisible(visible);

  return (
    <div className={cn(s.wrapper, className)}>
      <Popover
        trigger={'click'}
        open={visible}
        onOpenChange={handleVisibleChange}
        placement={'bottomRight'}
        content={
          profile && (
            <div className={s.content}>
              <ProfileDetails profile={profile} setVisible={setVisible} />
            </div>
          )
        }
      >
        <Button icon={'IcoUser'} circle loading={isLoading} />
      </Popover>
    </div>
  );
};
