import { useIsMounted } from '@/hooks/helpers';
import { ActionCallback } from '@/types/common/form';
import { PopoverProps } from 'antd';
import { useCallback, useState } from 'react';

export type UseDialogReturnType<Payload, Response = any> = {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  openDialog: () => void;
  closeDialog: () => void;
  toggleDialog: () => void;
  onFinish: ActionCallback<Response, Payload>;
};
export const useDialog = <Response, Payload>(
  onComplete?: ActionCallback<Response>,
): UseDialogReturnType<Payload, Response> => {
  const isMounted = useIsMounted();
  const [visible, setVisible] = useState(false);

  const openDialog = useCallback(() => {
    setVisible(true);
  }, []);

  const closeDialog = useCallback(() => {
    setTimeout(() => isMounted.current && setVisible(false), 0); // covered some cases with memory leak
  }, [isMounted]);

  const toggleDialog = () => setVisible((prevState) => !prevState);

  // const onFinish: (response?: (Response | undefined), values?: (Payload | undefined)) => Promise<void>
  const onFinish: ActionCallback<Response, Payload> = useCallback(
    async (...args) => {
      await onComplete?.(...args);
      setVisible(false);
    },
    [onComplete],
  );

  return {
    visible,
    setVisible,
    openDialog,
    closeDialog,
    toggleDialog,
    onFinish, // todo: rename -> onComplete
  };
};

type UseSmartPopoverReturnType = {
  visible: boolean;
  closeDialog: () => void;
  openDialog: () => void;
  smartPopover: Pick<
    PopoverProps,
    'visible' | 'onVisibleChange' | 'destroyTooltipOnHide' | 'trigger' | 'placement' | 'zIndex'
  >;
};
export const useSmartPopover = (): UseSmartPopoverReturnType => {
  const { visible, closeDialog, openDialog, setVisible } = useDialog();

  return {
    visible,
    closeDialog,
    openDialog,
    smartPopover: {
      visible,
      onVisibleChange: setVisible,
      trigger: ['click'],
      destroyTooltipOnHide: true,
      placement: 'bottom',
      zIndex: 1050,
    },
  };
};
