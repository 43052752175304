import { IOrderCreatePayload } from '@/types/order';
import { IPriceQuote } from '@/types/priceQuote';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type IOrderState = {
  orderPayload: Partial<IOrderCreatePayload>
  priceQuote: Partial<IPriceQuote>
}

export const orderInitialState: IOrderState = {
  orderPayload: {
    title: '',
    details: '',
    sources: 1,
    idExtraFeatures: [],
    units: 1,

  },
  priceQuote: {
    dynamicBodyPrice: undefined,
    dynamicTotalPrice: undefined,
    associationExtraFeatures: [],
  },
};

export const orderSlice = createSlice({
  name: 'order',
  initialState: orderInitialState,
  reducers: {
    setOrderPayload: (state: IOrderState, { payload }: PayloadAction<Partial<IOrderCreatePayload>>) => {
      state.orderPayload = payload;
    },
    setOrderDiscountCode: (state: IOrderState, { payload }: PayloadAction<string>) => {
      state.orderPayload = { ...state.orderPayload, discountCode: payload };
    },
    setPriceQuote: (state: Partial<IOrderState>, { payload }: PayloadAction<Partial<IPriceQuote>>) => {
      state.priceQuote = payload;
    },
    restoreOrderState: () => orderInitialState,
  },
});

export default orderSlice.reducer;

export const { setOrderPayload, setPriceQuote, restoreOrderState, setOrderDiscountCode } = orderSlice.actions;