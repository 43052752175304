import { useAppContext } from '@/app/contexts/AppContext';
import { Button } from '@/components/common/button/Button';
import { Modal } from '@/components/common/Modal';
import cn from 'classnames';
import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import s from './PaymentStatusModal.module.less';

type Props = {
  className?: string;
};

export const PaymentStatusModal = ({ className }: Props) => {
  const {
    config: {
      assets: {
        payment: { success, error },
      },
    },
  } = useAppContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const paymentStatus = searchParams.get('payment');

  const isSuccess = paymentStatus === 'success';
  const isError = paymentStatus === 'failed';

  const [visible, setVisible] = useState(isSuccess || isError);

  const closeModal = () => {
    setVisible(false);
    searchParams.delete('payment');
    setSearchParams(searchParams);
  };

  return (
    <Modal
      width={484}
      className={s.modal}
      open={visible}
      onCancel={closeModal}
    >
      <div
        className={cn(s.wrapper, className, {
          [s.success]: paymentStatus,
        })}
      >
        <img src={isSuccess ? success : error} className={s.image} alt={isSuccess ? 'success' : 'error'} />
        <div className={s.heading}>{isSuccess ? 'Success!' : 'We’re sorry, but something went wrong!'}</div>
        <div className={s.text}>
          {isSuccess ? (
            <span>
              Thank you for your purchase. Your work is now in good hands of our experts. If you have any questions feel free to{' '}
              <span className={s.supportLink}>contact our support team</span>.
            </span>
          ) : (
            <span>
              Please try to submit your payment again. If the error keeps appearing, please{' '}
              <span className={s.supportLink}>contact support</span>.
            </span>
          )}
        </div>
        <Button onClick={closeModal} modification={'success'}>
          View Order
        </Button>
      </div>
    </Modal>
  );
};
