import { useLazyGetLegacyMagicLinkQuery } from '@/api/auth';
import { useLazyViewCurrentUserProfileQuery } from '@/api/profile';
import { ErrorPage } from '@/components/common/error/ErrorPage';
import { SpinFullscreen } from '@/components/common/spin/SpinFullscreen';
import { INITIAL_PREFERENCES } from '@/config/constants';
import { useTrackBreakpoint } from '@/hooks/breakpoints';
import { useCssVariables } from '@/hooks/cssVariables';
import { useAppDispatch } from '@/hooks/redux';
import { useViewPortHeight } from '@/hooks/viewportHeight';
import { useAuth, useMagicLogin, useQueryLogout } from '@/store/auth/authHooks';
import { prefetchOrderForm, prefetchPreferences, resetApiState, useGetPreferencesQuery } from '@/store/emptyApi';
import { setMaintenance } from '@/store/global/globalSlice';
import { TAppContext } from '@/types/common/components';
import { merge } from 'lodash';
import React, { createContext, useContext, useEffect, useState } from 'react';

export const AppContext = createContext<TAppContext>({
  breakpoint: 'xxl',
  isLegacyLinkLoading: true,
  isTouchDevice: false,
  config: INITIAL_PREFERENCES,
});

export const useAppContext = () => useContext(AppContext);

export const AppProvider = ({ children }) => {
  const { data: preferences, isLoading, isError } = useGetPreferencesQuery();
  const breakpoint = useTrackBreakpoint();
  useViewPortHeight();
  const { tokens, isLoggedIn } = useAuth();
  const [getProfile, { data: profile }] = useLazyViewCurrentUserProfileQuery();
  const [getLegacyLink, { data: legacyLink }] = useLazyGetLegacyMagicLinkQuery();

  const legacyAccount = profile?.legacyAccount;

  const dispatch = useAppDispatch();

  const [prevToken, setPrevToken] = useState<string | null>(null);

  useCssVariables(preferences?.theme);

  useEffect(() => {
    dispatch(setMaintenance(false));
  }, [dispatch]);

  useEffect(() => {
    if (prevToken && tokens.accessToken && prevToken !== tokens.accessToken) {

      // todo: move to function (duplicate in authActions!!!)
      dispatch(resetApiState());
      dispatch(prefetchPreferences());
      dispatch(prefetchOrderForm())
    }

    if (tokens.accessToken) {
      setPrevToken(tokens.accessToken);
    }
  }, [dispatch, prevToken, tokens]);

  useEffect(() => {
    if (isLoggedIn) {
      getProfile();
    }
  }, [getProfile, isLoggedIn]);

  useQueryLogout();
  useMagicLogin();

  const isTouchDevice = !window.matchMedia('(hover: hover) and (pointer: fine)').matches;

  useEffect(() => {
    if (legacyAccount) {
      getLegacyLink(undefined, true);
    }
  }, [legacyAccount, getLegacyLink]);

  if (isLoading) return <SpinFullscreen />;
  if (isError || !preferences) return <ErrorPage type={'Maintenance'} />;

  return (
    <AppContext.Provider
      value={{
        breakpoint,
        isLegacyLinkLoading: !legacyLink,
        legacyLink: legacyLink?.magicUuid,
        isTouchDevice,
        config: merge(INITIAL_PREFERENCES, preferences),
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
