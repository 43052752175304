import cn from 'classnames';
import { ReactNode } from 'react';
import s from './Container.module.less';

type Props = {
  children: ReactNode;
  className?: string
}

export const Container = ({ children, className }: Props) => {
  return (
    <div className={cn(s.wrapper, className)}>
      {children}
    </div>
  );
};