import { FEATURE } from '@/config/features';
import { emptyApi, getPath, getTags } from '@/store/emptyApi';
import { AssociationCustomerFileTicket, AssociationUserFileTicket, AssociationWriterFileTicket } from '@/types/file/file';
import { SearchView } from '@/types/search';
import { ITicket, ITicketCreateRequest, ITicketDetails, ITicketFilesRequest, ITicketSearchItem, ITicketSearchParams, ITicketStatusRequest, ITicketUpdateRequest } from '@/types/ticket';

const { type, path } = FEATURE.Ticket;
const { invalidatesTags, providesTags } = getTags(type, [FEATURE.Order.type, FEATURE.OwnFile.type]);

const injectedRtkApi = emptyApi.injectEndpoints({
  endpoints: (build) => ({
    searchTickets: build.query<SearchView<ITicketSearchItem>, ITicketSearchParams>({
      query: (params) => ({ url: `${path}`, params }),
      providesTags: [{ type, id: 'search' }],
    }),
    getTicket: build.query<ITicketDetails, number>({
      query: (id) => ({ url: `${path}/${id}` }),
      providesTags,
    }),
    createTicket: build.mutation<ITicket, ITicketCreateRequest>({
      query: ({ id, body, context }) => ({
        url: `${getPath(context)}${id ? '/' + id : ''}${path}`,
        method: 'POST',
        body,
      }),
      invalidatesTags: () => [{ type }, { type: FEATURE.Order.type }],
    }),
    updateTicket: build.mutation<ITicket, ITicketUpdateRequest>({
      query: ({ id, body }) => ({ url: `${path}/${id}`, method: 'PUT', body }),
      invalidatesTags,
    }),
    resolveTicket: build.mutation<ITicket, ITicketStatusRequest>({
      query: ({ id, body }) => ({ url: `${path}/${id}/resolve`, method: 'PATCH', body }),
      invalidatesTags,
    }),
    withdrawTicket: build.mutation<ITicket, ITicketStatusRequest>({
      query: ({ id, body }) => ({ url: `${path}/${id}/withdraw`, method: 'PATCH', body }),
      invalidatesTags,
    }),
    getTicketACustomerFiles: build.query<AssociationCustomerFileTicket[], number>({
      query: (id) => ({ url: `${path}/${id}/a/customer-files` }),
      providesTags: (result, error, id) => [{ type, id: 'customerFiles' + id }],
    }),
    getTicketAUserFiles: build.query<AssociationUserFileTicket[], number>({
      query: (id) => ({ url: `${path}/${id}/a/user-files` }),
      providesTags: (result, error, id) => [{ type, id: 'userFiles' + id }],
    }),
    getTicketAWriterFiles: build.query<AssociationWriterFileTicket[], number>({
      query: (id) => ({ url: `${path}/${id}/a/writer-files` }),
      providesTags: (result, error, id) => [{ type, id: 'writerFiles' + id }],
    }),
    claimTicketACustomerFiles: build.mutation<AssociationCustomerFileTicket[], ITicketFilesRequest>({
      query: ({ id, body }) => ({ url: `${path}/${id}/a/customer-files/claim`, method: 'PUT', body }),
      invalidatesTags: (result, error, { id }) => [{ type, id: 'customerFiles' + id }, 'OwnFile'],
    }),
    withdrawTicketACustomerFiles: build.mutation<AssociationCustomerFileTicket[], ITicketFilesRequest>({
      query: ({ id, body }) => ({ url: `${path}/${id}/a/customer-files/withdraw`, method: 'DELETE', body }),
      invalidatesTags: (result, error, { id }) => [{ type, id: 'customerFiles' + id }, 'OwnFile'],
    }),
  }),
  overrideExisting: false,
});

export const {
  useSearchTicketsQuery,
  useLazySearchTicketsQuery,
  useGetTicketQuery,
  useCreateTicketMutation,
  useUpdateTicketMutation,
  useResolveTicketMutation,
  useWithdrawTicketMutation,
  useGetTicketACustomerFilesQuery,
  useClaimTicketACustomerFilesMutation,
  useWithdrawTicketACustomerFilesMutation,
  useGetTicketAUserFilesQuery,
  useGetTicketAWriterFilesQuery,
} = injectedRtkApi;
