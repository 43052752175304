import { useAppContext } from '@/app/contexts/AppContext';
import cn from 'classnames';
import s from './Logo.module.less';

type Props = {
  alt?: string;
  className?: string
}

export const Logo = ({ alt, className }: Props) => {
  const { config: { assets: { logo }, site } } = useAppContext()

  return (
    <div className={cn(s.wrapper, className)}>
      <a href={site.url || '/'}>
        <img src={logo} className={s.logo} alt={alt || 'logo'} />
      </a>
      {/* TODO: Sync with all sites */}
    </div>
  );
};