// Sync this type with _variables.app.less
// TODO: Sync it with new cssVariables
import { lowerCase, upperFirst } from 'lodash';

export type AppColors =
  | 'brand'
  | 'text'
  | 'success'
  | 'error'
  | 'warning'
  | 'muted'
  | 'info'
  | 'danger'
  | 'support'
  | 'writer'
  | 'customer'
  | 'text-alt';

export const getCSSVar = (name: string) => getComputedStyle(document.documentElement).getPropertyValue(name);

export const getColor = (color: AppColors) => {
  return getCSSVar(`--${color}-color`);
};

export const isTouchDevice = () => !window.matchMedia('(hover: hover) and (pointer: fine)').matches;

export const stripTags = (str?: string) => str?.replace(/(<([^>]+)>)/gi, '');

export interface CustomScrollAction {
  el: Element;
  top: number;
  left: number;
}

export const customScrollActions = (actions: CustomScrollAction[], extraOffset: number) => {
  const headerHeight = getCSSVar('--header-height');
  const offset = parseInt(headerHeight, 10) + extraOffset;
  return actions.forEach(({ el, top }) => (el.scrollTop = top - offset));
};

export const hexToRgb = (hex) => {
  let rgb = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex.trim());
  return (
    rgb
      ? [
        parseInt(rgb[1], 16), // r
        parseInt(rgb[2], 16), // g
        parseInt(rgb[3], 16), // b
      ]
      : [0, 0, 0, 1]
  ).join(',');
};

export const normalCase = (str: string) => upperFirst(lowerCase(str));
export const replaceTemplate = (str: string, s: string) => (str.match('%s') ? str.replace('%s', s) : str);

export const getFirstFromList = (list): number | undefined => (list?.length ? list[0]?.id : undefined);
