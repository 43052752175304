import { useViewCurrentUserProfileQuery } from '@/api/profile';
import { ErrorPage } from '@/components/common/error/ErrorPage';
import { SpinFullscreen } from '@/components/common/spin/SpinFullscreen';
import { usePreferences } from '@/hooks/preferences';
import React, { lazy } from 'react';
import { Navigate, Route, Routes, useSearchParams } from 'react-router-dom';

const ThemeCustomizerPage = lazy(() => import('@/components/theme/ThemeCustomizerPage'));

const ProfilePage = lazy(() => import('@/components/pages/ProfilePage'));
const OrderSinglePage = lazy(() => import('@/components/pages/OrderSinglePage'));
const OrderSearchPage = lazy(() => import('@/components/pages/OrderSearchPage'));
const OrderSnapshotSearchPage = lazy(() => import('@/components/pages/OrderSnapshotSearchPage'));
const RevisionSinglePage = lazy(() => import('@/components/pages/RevisionSinglePage'));
const TicketSearchPage = lazy(() => import('@/components/pages/TicketSearchPage'));
const TicketSinglePage = lazy(() => import('@/components/pages/TicketSinglePage'));
// LEGACY
const LegacyPage = lazy(() => import('@/components/pages/LegacyPage'));

export const UserRoutes = () => {
  const [searchParams] = useSearchParams();

  const { data: profile, error } = useViewCurrentUserProfileQuery(); // todo: move profile to globalState

  const { initialPreferencesSet } = usePreferences(!!profile, profile?.preferences);

  if (error) return <ErrorPage type={'500'} />;

  if (!initialPreferencesSet) return <SpinFullscreen />; // todo: if !profile -> permanent spin??

  if (!profile) return <ErrorPage type={'500'} />;

  return (
    <Routes>
      <Route
        index
        element={
          <Navigate
            to={{
              pathname: '/orders',
              search: searchParams.toString(),
            }}
            replace={true}
          />
        }
      />
      <Route path={'/orders'} element={<OrderSearchPage />} />
      <Route path={'/orders/:id'} element={<OrderSinglePage />} />
      <Route path={'/order-snapshots'} element={<OrderSnapshotSearchPage />} />
      <Route path={'/profile'} element={<ProfilePage />} />
      <Route path={'/reset-password/:magicUuid'} element={<Navigate to={'/profile?tab=settings#password'} />} />
      <Route path={'/revisions/:id'} element={<RevisionSinglePage />} />
      <Route path={'/tickets'} element={<TicketSearchPage />} />
      <Route path={'/tickets/:id'} element={<TicketSinglePage />} />
      {['/login', '/register'].map((path) => (
        <Route path={path} key={path} element={<Navigate to={'/orders'} />} />
      ))}

      {/* LEGACY */}
      {profile.legacyAccount && ['/orderinfo/*', '/dashboard/*'].map((path) => <Route key={path} path={path} element={<LegacyPage />} />)}
      {profile.legacyAccount &&
        ['/loyalty/*', '/transactions/*'].map((path) => <Route key={path} path={path} element={<Navigate to={'/orders'} />} />)}
      {/* LEGACY */}

      <Route path={'/adm/theme'} element={<ThemeCustomizerPage />} />

      <Route path={'*'} element={<ErrorPage type={'404'} />} />
    </Routes>
  );
};
