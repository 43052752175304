import { useAuthLoginMutation, useAuthLogoutMutation } from '@/api/auth';
import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import { selectAuth, setTokens } from '@/store/auth/authSlice';
import { RootState } from '@/store/store';
import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { authLogout } from '@/store/auth/authActions';

export const useAuth = (): RootState['auth'] => useAppSelector(selectAuth);

export const useQueryLogout = () => {
  const [requestLogout] = useAuthLogoutMutation();
  const navigate = useNavigate();
  const { isLoggedIn } = useAuth();

  const [searchParams, setSearchParams] = useSearchParams();
  const logoutParam = searchParams.get('logout');

  useEffect(() => {
    if (logoutParam !== null && isLoggedIn) {
      searchParams.delete('logout');
      setSearchParams(searchParams);
      requestLogout()
        .then(() => navigate('/login'))
        .catch();
    }
  }, [isLoggedIn, logoutParam, navigate, requestLogout, searchParams, setSearchParams]);
};
export const useMagicLogin = () => {
  const dispatch = useAppDispatch();
  const [magicLogin] = useAuthLoginMutation();
  const [searchParams, setSearchParams] = useSearchParams();
  const magicLinkParam = searchParams.get('magicLink');

  useEffect(() => {
    if (magicLinkParam !== null) {
      magicLogin({ magicUuid: magicLinkParam })
        .unwrap()
        .then((tokens) => {
          dispatch(authLogout());
          setTimeout(() => {
            dispatch(setTokens(tokens));
          }, 0);
        })
        .catch();
      searchParams.delete('magicLink');
      setSearchParams(searchParams);
    }
  }, [dispatch, magicLinkParam, magicLogin, searchParams, setSearchParams]);
};
