import { Badge } from 'antd';
import { BadgeProps } from 'antd/lib/badge';
import cn from 'classnames';
import React, { FC, ReactNode } from 'react';

// Styles
import s from './BadgeCounter.module.less';

interface IProps extends BadgeProps {
  text?: ReactNode;
  count: number | undefined;
  type?: 'default' | 'alert' | 'tab';
}

export const BadgeCounter: FC<IProps> = ({ text, className, type = 'default', ...props }) => {
  if (!props.count) return <div>{props.children || text}</div>;
  return (
    <>
      {text && <span className={s.prefix}>{text}</span>}
      <Badge overflowCount={100000} className={cn(s.wrapper, s[type], className)} {...props} />
    </>
  );
};
