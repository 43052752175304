import { IconSvg } from '@/components/common/icons/IconSvg';
import { useDialog } from '@/hooks/dialog';
import { Modal as AntModal, ModalProps } from 'antd';
import { FC, ReactNode } from 'react';

interface Props extends ModalProps {
  trigger?: ReactNode;
}

export const Modal: FC<Props> = ({ className, trigger, ...props }) => {
  const { visible, openDialog, closeDialog } = useDialog();
  return (
    <>
      {trigger && <span onClick={openDialog}>{trigger}</span>}
      <AntModal
        open={visible}
        onCancel={closeDialog}
        className={className}
        footer={null}
        destroyOnClose
        closeIcon={<IconSvg icon={'IcoRXmark'} />}
        width={450}
        bodyStyle={{ padding: '15px 19px 19px' }} // compact modal
        focusTriggerAfterClose
        {...props}
      />
    </>
  );
};
