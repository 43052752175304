import { SearchEngineView } from '@/components/search/searchEngine/config';
import { getPreferencesWithoutPages } from '@/helpers/preferences';
import { RootState } from '@/store/store';
import { ProfilePreferences } from '@/types/profile';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface SearchDictInterface {
  [key: string]: SearchDict | undefined;
}

export type SearchDict = Record<string, string | number | boolean | SearchDictInterface>;

const initialState: ProfilePreferences = {
  idPinnedTicket: 0,
};

export const preferencesSlice = createSlice({
  name: 'preferences',
  initialState,
  reducers: {
    setSearchPreferences: (state: ProfilePreferences, { payload }: PayloadAction<SearchDictInterface>) => {
      state.search = payload;
    },
    setCurrentTimezone: (state: ProfilePreferences, { payload }: PayloadAction<string>) => {
      state.currentTimezone = payload;
    },
    setAvatar: (state: ProfilePreferences, { payload }: PayloadAction<string>) => {
      state.avatar = payload;
    },
    resetSearchPagination: (state: ProfilePreferences) => getPreferencesWithoutPages(state),
    setPreferences: (state: ProfilePreferences, { payload }: PayloadAction<ProfilePreferences>) => payload,
    resetPreferencesState: () => initialState,
  },
});

export const { setSearchPreferences, setPreferences, resetPreferencesState, resetSearchPagination, setCurrentTimezone, setAvatar } =
  preferencesSlice.actions;

export default preferencesSlice.reducer;

export const preferencesSelector = ({ preferences }: RootState): ProfilePreferences => preferences;
export const debugModeSelector = ({ preferences }: RootState): boolean => !!preferences?.debugMode;
export const currentTimezoneSelector = ({ preferences }: RootState): string | undefined => preferences?.currentTimezone;
export const avatarSelector = ({ preferences }: RootState): string | undefined => preferences?.avatar;

export const idPinnedTicketSelector = ({ preferences }: RootState): number => preferences.idPinnedTicket;

export const searchSelectorByView =
  (view: SearchEngineView) =>
  (state: RootState): SearchDict | undefined =>
    state.preferences?.search?.[view];
