import { FEATURE } from '@/config/features';
import { setUser } from '@/store/auth/authSlice';
import { emptyApi, getTags } from '@/store/emptyApi';
import { ILogMessage } from '@/types/common/api';
import {
  IAssociationCommunicationChannel,
  IAssociationEmail,
  IAssociationPhone,
  IEmailAbandonPayload,
  IEmailClaimPayload,
  IEmailSetPrimaryPayload,
  IProfile,
  IProfileAbandonPhonePayload,
  IProfileChangePasswordRequest,
  IProfileClaimPhonePayload,
  IProfileInfo,
  IProfilePatchCountryRequest,
  IProfilePatchDoNotDisturbHoursRequest,
  IProfilePersonalInfo,
  IProfileSetPasswordRequest,
  IProfileSetPrimaryPhonePayload,
  IProfileSyncAssociationCommunicationChannelRequest,
  IProfileUpdatePayload,
  IProfileUpdatePrivacySettingsRequest,
  IUpdateProfilePreferencesRequest,
} from '@/types/profile';
import { RootState } from '@/store/store';
import { updatePreferences } from '@/store/preferences/preferencesActions';

const { type, path } = FEATURE.Profile;
const { providesTags, invalidatesTags } = getTags(type);

const injectedRtkApi = emptyApi.injectEndpoints({
  endpoints: (build) => ({
    viewCurrentUserProfile: build.query<IProfileInfo, void>({
      query: () => ({
        url: `${path}`,
      }),
      async onCacheEntryAdded(_, { dispatch, cacheDataLoaded, getState }) {
        const { data } = await cacheDataLoaded;

        const state = getState() as RootState;

        // set preferences on login
        if (data?.preferences && !Object.keys(state.preferences).length) {
          dispatch(updatePreferences(data.preferences));
        }

        dispatch(setUser(data)); // sync (messenger required)
      },
      providesTags,
    }),
    getProfilePersonalInfo: build.query<IProfilePersonalInfo, void>({
      query: () => ({
        url: `${path}/personal-info`,
      }),
      providesTags,
    }),
    updateProfile: build.mutation<IProfile, IProfileUpdatePayload>({
      query: (body) => ({ url: `${path}/update`, method: 'PUT', body }),
      invalidatesTags,
    }),
    updateProfilePreferences: build.mutation<void, IUpdateProfilePreferencesRequest>({
      query: (body) => ({ url: `${path}/preferences`, method: 'PATCH', body }),
    }),
    patchProfileDoNotDisturb: build.mutation<void, IProfilePatchDoNotDisturbHoursRequest>({
      query: (body) => ({
        url: `${path}/do-not-disturb`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags,
    }),
    patchProfileContactableAnytime: build.mutation<void, void>({
      query: () => ({
        url: `${path}/contactable-anytime`,
        method: 'PATCH',
      }),
      invalidatesTags,
    }),
    patchProfileCountry: build.mutation<void, IProfilePatchCountryRequest>({
      query: (body) => ({
        url: `${path}/country`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags,
    }),
    patchProfileUpdatePassword: build.mutation<void, IProfileChangePasswordRequest>({
      query: (body) => ({
        url: `${path}/update-password`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags,
    }),
    patchProfileSetPassword: build.mutation<void, IProfileSetPasswordRequest>({
      query: (body) => ({
        url: `${path}/set-password`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags,
    }),
    patchProfilePrivacySettings: build.mutation<void, IProfileUpdatePrivacySettingsRequest>({
      query: (body) => ({
        url: `${path}/privacy-settings`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags,
    }),
    claimProfileAPhone: build.mutation<IAssociationPhone, IProfileClaimPhonePayload>({
      query: (body) => ({ url: `${path}/a/phones/claim`, method: 'PUT', body }),
      invalidatesTags,
    }),
    abandonProfileAPhone: build.mutation<IAssociationPhone, IProfileAbandonPhonePayload>({
      query: (body) => ({ url: `${path}/a/phones/abandon`, method: 'DELETE', body }),
      invalidatesTags,
    }),
    setPrimaryProfileAPhone: build.mutation<IAssociationPhone, IProfileSetPrimaryPhonePayload>({
      query: (body) => ({ url: `${path}/a/phones/primary`, method: 'PATCH', body }),
      invalidatesTags,
    }),
    claimProfileAEmail: build.mutation<IAssociationEmail, IEmailClaimPayload>({
      query: (body) => ({ url: `${path}/a/emails/claim`, method: 'PUT', body }),
      invalidatesTags,
    }),
    abandonProfileAEmail: build.mutation<IAssociationEmail, IEmailAbandonPayload>({
      query: (body) => ({ url: `${path}/a/emails/abandon`, method: 'DELETE', body }),
      invalidatesTags,
    }),
    setPrimaryProfileAEmail: build.mutation<IAssociationPhone, IEmailSetPrimaryPayload>({
      query: (body) => ({ url: `${path}/a/emails/primary`, method: 'PATCH', body }),
      invalidatesTags,
    }),
    syncProfileACommunication: build.mutation<IAssociationCommunicationChannel, IProfileSyncAssociationCommunicationChannelRequest>({
      query: (body) => ({ url: `${path}/a/communication-channels/sync`, method: 'PUT', body }),
      invalidatesTags,
    }),
    removeProfileAccount: build.mutation<void, ILogMessage>({
      query: (body) => ({ url: `${path}/delete-account`, method: 'DELETE', body }),
      invalidatesTags,
    }),
  }),
  overrideExisting: false,
});

export const {
  useViewCurrentUserProfileQuery,
  useLazyViewCurrentUserProfileQuery,
  useGetProfilePersonalInfoQuery,
  useUpdateProfileMutation,
  usePatchProfileDoNotDisturbMutation,
  usePatchProfileContactableAnytimeMutation,
  usePatchProfileCountryMutation,
  usePatchProfileUpdatePasswordMutation,
  usePatchProfileSetPasswordMutation,
  useClaimProfileAPhoneMutation,
  useAbandonProfileAPhoneMutation,
  useSetPrimaryProfileAPhoneMutation,
  useClaimProfileAEmailMutation,
  useAbandonProfileAEmailMutation,
  useSetPrimaryProfileAEmailMutation,
  useSyncProfileACommunicationMutation,
  useRemoveProfileAccountMutation,
  usePatchProfilePrivacySettingsMutation,
  useUpdateProfilePreferencesMutation,
} = injectedRtkApi;
