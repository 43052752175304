import { FEATURE } from '@/config/features';
import { emptyApi } from '@/store/emptyApi';
import {
  IAuthLoginRequest,
  IAuthSignUpPayload,
  IAuthSignUpRequest,
  IMagicUuid,
  IPasswordRecoverRequest,
  IPasswordRecoverUpdateRequest,
  ITokenPair,
} from '@/types/auth';
import { setTokens } from '@/store/auth/authSlice';
import { authLogout } from '@/store/auth/authActions';

const { path } = FEATURE.Auth;

const injectedRtkApi = emptyApi.injectEndpoints({
  endpoints: (build) => ({
    authLogin: build.mutation<ITokenPair, IAuthLoginRequest>({
      query: (body) => {
        return {
          url: `${path}/login`,
          method: 'POST',
          body,
        };
      },
      async onCacheEntryAdded(_, { dispatch, cacheDataLoaded }) {
        const { data } = await cacheDataLoaded;
        dispatch(setTokens(data));
      },
    }),
    authSignUp: build.mutation<ITokenPair, IAuthSignUpPayload>({
      query: (credentials) => {
        const body: IAuthSignUpRequest = {
          ...credentials,
        };
        return {
          url: `${path}/sign-up`,
          method: 'POST',
          body,
        };
      },
    }),
    authLogout: build.mutation<unknown, void>({
      query: () => ({
        url: `${path}/logout`,
        method: 'POST',
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(authLogout());
      },
    }),
    getLegacyMagicLink: build.query<IMagicUuid, void>({
      query: () => ({ url: `${path}/magic-login/legacy/request` }),
      extraOptions: { customErrorHandler: true },
    }),
    requestAuthPasswordRecovery: build.mutation<void, IPasswordRecoverRequest>({
      query: (body) => ({ url: `${path}/magic-password-recovery/request`, body, method: 'POST' }),
      extraOptions: { customErrorHandler: true },
    }),
    recoverAuthPassword: build.mutation<ITokenPair, IPasswordRecoverUpdateRequest>({
      query: (body) => ({ url: `${path}/magic-password-recovery`, body, method: 'POST' }),
      extraOptions: { customErrorHandler: true },
    }),
  }),
  overrideExisting: false,
});

export const {
  useAuthLoginMutation,
  useAuthLogoutMutation,
  useAuthSignUpMutation,
  useLazyGetLegacyMagicLinkQuery,
  useRequestAuthPasswordRecoveryMutation,
  useRecoverAuthPasswordMutation,
} = injectedRtkApi;
