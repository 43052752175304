import { useAppContext } from '@/app/contexts/AppContext';
import { getCSSVar } from '@/helpers/utils';
import { FCWithChildren } from '@/react-app-env';
import { TWindowBreakpoint } from '@/types/common/components';
import { throttle } from 'lodash';
import { useState, useEffect, useCallback } from 'react';

export const useTrackBreakpoint = () => {
  const [breakpoint, setBreakPoint] = useState<TWindowBreakpoint>('xxl');

  const watchBreakPoint = useCallback(() => {
    const breakPoints: [TWindowBreakpoint, string][] = [
      ['xs', getCSSVar('--xs')],
      ['sm', getCSSVar('--sm')],
      ['md', getCSSVar('--md')],
      ['lg', getCSSVar('--lg')],
      ['xl', getCSSVar('--xl')],
      ['xxl', getCSSVar('--xxl')],
    ];

    const targetBreakPoint = breakPoints.find(([_, size]) => {
      return window.matchMedia(`only screen and (max-width: ${size}px)`).matches;
    });

    setBreakPoint(targetBreakPoint?.[0] || 'xxxl');
  }, []);

  useEffect(() => {
    watchBreakPoint();

    const debouncedWatchBreakPoint = throttle(watchBreakPoint, 100);
    window.addEventListener('resize', debouncedWatchBreakPoint);

    return () => {
      window.removeEventListener('resize', debouncedWatchBreakPoint);
    };
  }, [watchBreakPoint]);

  return breakpoint;
};

export const useBreakpoints = () => {
  const { breakpoint } = useAppContext();

  return {
    isXs: breakpoint === 'xs',
    isXsMin: !['xs'].includes(breakpoint),
    isSm: ['xs', 'sm'].includes(breakpoint),
    isSmMin: !['xs', 'sm'].includes(breakpoint),
    isMd: ['xs', 'sm', 'md'].includes(breakpoint),
    isMdMin: !['xs', 'sm', 'md'].includes(breakpoint),
    isLg: ['xs', 'sm', 'md', 'lg'].includes(breakpoint),
    isLgMin: !['xs', 'sm', 'md', 'lg'].includes(breakpoint),
    isXl: ['xs', 'sm', 'md', 'lg', 'xl'].includes(breakpoint),
    isXlMin: !['xs', 'sm', 'md', 'lg', 'xl'].includes(breakpoint),
    isXxl: ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'].includes(breakpoint),
    isXxlMin: !['xs', 'sm', 'md', 'lg', 'xl', 'xxl'].includes(breakpoint),
    isXxxl: breakpoint === 'xxxl',
  };
};

export const WinXs: FCWithChildren = ({ children }) => {
  const { isXs } = useBreakpoints();
  return isXs && children ? <>{children}</> : null;
};

export const WinXsMin: FCWithChildren = ({ children }) => {
  const { isXsMin } = useBreakpoints();
  return isXsMin && children ? <>{children}</> : null;
};

export const WinSm: FCWithChildren = ({ children }) => {
  const { isSm } = useBreakpoints();
  return isSm && children ? <>{children}</> : null;
};

export const WinSmMin: FCWithChildren = ({ children }) => {
  const { isSmMin } = useBreakpoints();
  return isSmMin && children ? <>{children}</> : null;
};

export const WinMd: FCWithChildren = ({ children }) => {
  const { isMd } = useBreakpoints();
  return isMd && children ? <>{children}</> : null;
};

export const WinMdMin: FCWithChildren = ({ children }) => {
  const { isMdMin } = useBreakpoints();
  return isMdMin && children ? <>{children}</> : null;
};

export const WinLg: FCWithChildren = ({ children }) => {
  const { isLg } = useBreakpoints();
  return isLg && children ? <>{children}</> : null;
};

export const WinLgMin: FCWithChildren = ({ children }) => {
  const { isLgMin } = useBreakpoints();
  return isLgMin && children ? <>{children}</> : null;
};

export const WinXl: FCWithChildren = ({ children }) => {
  const { isXl } = useBreakpoints();
  return isXl && children ? <>{children}</> : null;
};

export const WinXlMin: FCWithChildren = ({ children }) => {
  const { isXlMin } = useBreakpoints();
  return isXlMin && children ? <>{children}</> : null;
};

export const WinXxl: FCWithChildren = ({ children }) => {
  const { isXxl } = useBreakpoints();
  return isXxl && children ? <>{children}</> : null;
};

export const WinXxlMin: FCWithChildren = ({ children }) => {
  const { isXxlMin } = useBreakpoints();
  return isXxlMin && children ? <>{children}</> : null;
};

export const WinXxxl: FCWithChildren = ({ children }) => {
  const { isXxxl } = useBreakpoints();
  return isXxxl && children ? <>{children}</> : null;
};
