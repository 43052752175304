import { AppProvider } from '@/app/contexts/AppContext';
import { GuestRoutes } from '@/app/routes/GuestRoutes';
import { UserRoutes } from '@/app/routes/UserRoutes';
import { AppBaseLayout } from '@/components/common/layout/AppBaseLayout';
import { SpinFullscreen } from '@/components/common/spin/SpinFullscreen';
import { PaymentStatusModal } from '@/components/payment/PaymentStatusModal';
import { useAuth } from '@/store/auth/authHooks';
import React, { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';

const OrderFormPage = lazy(() => import('@/components/pages/OrderFormPage'));

function App() {
  const { isLoggedIn } = useAuth();
  return (
    <AppProvider>
      <AppBaseLayout>
        <Suspense fallback={<SpinFullscreen />}>
          <Routes>
            <Route path={'/order'} element={<OrderFormPage />} />
            <Route path={'*'} element={isLoggedIn ? <UserRoutes /> : <GuestRoutes />} />
          </Routes>
        </Suspense>
      </AppBaseLayout>
      {isLoggedIn && <PaymentStatusModal />}
    </AppProvider>
  );
}

export default App;
