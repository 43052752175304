import { Button } from '@/components/common/button/Button';
import { PhoneNumber } from '@/components/common/contacts/PhoneNumber';
import { Dot } from '@/components/common/icons/Dot';
import { IconSvg } from '@/components/common/icons/IconSvg';
import { Flex } from '@/components/common/layout/Flex';
import { FEATURE } from '@/config/features';
import { IProfileInfo } from '@/types/profile';
import { CountryPreview } from '@feature/country/CountryPreview';
import { Avatar, Collapse, Divider, Space } from 'antd';
import cn from 'classnames';
import moment from 'moment';
import { useLocation, useNavigate } from 'react-router-dom';

import s from './ProfileDetails.module.less';

type Props = {
  profile: IProfileInfo;
  setVisible?: (bool: boolean) => void;
  className?: string;
};

export const ProfileDetails = ({ className, profile, setVisible }: Props) => {
  const navigate = useNavigate();
  const pathname = useLocation().pathname;

  const {
    firstName,
    lastName,
    dateAdded,
    primaryPhone,
    primaryEmail,
    associationCommunicationChannels,
    associationPhones,
    associationEmails,
    country,
    doNotDisturbStart,
    doNotDisturbEnd,
    contactableAnytime,
  } = profile;

  const onSettingsClick = () => {
    navigate('/profile');
    setVisible?.(false);
  };

  const secondaryEmails = associationEmails?.filter(({ isPrimary }) => !isPrimary);

  const showButton = pathname !== FEATURE.Profile.path;

  return (
    <div className={cn(s.wrapper, className)}>
      <Space direction={'vertical'} align={'center'} className={s.top}>
        <Avatar size={120} className={s.avatar} icon={<IconSvg icon={'IcoUser'} size={50} />} />
        <Space direction={'vertical'} align={'center'} className={s.general}>
          <div className={s.name}>{firstName || lastName ? `${firstName ?? firstName} ${lastName ?? lastName}` : 'Customer'}</div>
          {country && (
            <Space align={'center'} split={<Dot />} className={s.country}>
              {/* todo: if country.ts isn`t exist - show country.ts from Phone */}
              <CountryPreview country={country} />
            </Space>
          )}
        </Space>
      </Space>
      <Space direction={'vertical'} split={<Divider type={'horizontal'} />} className={s.content}>
        <Space direction={'vertical'}>
          <Flex gap={8}>
            <IconSvg icon={'IcoRCalendarClock'} />
            {contactableAnytime ? (
              'Calls 24/7'
            ) : doNotDisturbStart || doNotDisturbEnd ? (
              `${moment(doNotDisturbStart, 'hh:mm').format('HH:mm A')} – ${moment(doNotDisturbEnd, 'hh:mm').format('HH:mm A')}`
            ) : (
              <span className={s.linkText} onClick={onSettingsClick}>
                Set the best time to reach you
              </span>
            )}
          </Flex>
          <Flex gap={8}>
            <IconSvg icon={'IcoRComments'} />
            <Space split={<Dot />}>
              {!!associationCommunicationChannels?.length ? (
                associationCommunicationChannels.map(({ communicationChannel }) => (
                  <div key={communicationChannel.id}>{communicationChannel.name}</div>
                ))
              ) : (
                <span className={s.linkText} onClick={onSettingsClick}>
                  Set the best way to reach you
                </span>
              )}
            </Space>
          </Flex>
        </Space>
        {!primaryPhone && (
          <Flex gap={8}>
            <IconSvg icon={'IcoRPhone'} />
            <span className={s.linkText} onClick={onSettingsClick}>
              Set the best way to reach you
            </span>
          </Flex>
        )}
        <Collapse
          ghost
          expandIcon={(panelProps) => <IconSvg icon={panelProps.isActive ? 'IcoRChevronUp' : 'IcoRChevronDown'} />}
          expandIconPosition={'end'}
        >
          {primaryPhone && (
            <Collapse.Panel
              key={1}
              style={{ borderBottom: '1px solid #E4E8F1', borderRadius: 0, paddingBottom: 8 }}
              header={
                <Flex gap={8}>
                  <IconSvg icon={'IcoRPhone'} />
                  <PhoneNumber phone={primaryPhone} />
                </Flex>
              }
            >
              <div style={{ display: 'flex', gap: 8, flexDirection: 'column' }}>
                <span className={s.panelHeading}>Secondaries</span>
                {/* todo: nee common list component */}
                <Space direction={'vertical'}>
                  {!!associationPhones?.length ? (
                    associationPhones.filter(({ isPrimary }) => !isPrimary).map(({ phone }) => <PhoneNumber key={phone.id} phone={phone} />)
                  ) : (
                    <Button size={'small'} icon={'IcoRPlus'} onClick={onSettingsClick}>
                      Add
                    </Button>
                  )}
                </Space>
              </div>
            </Collapse.Panel>
          )}

          <Collapse.Panel
            key={2}
            style={primaryPhone ? { paddingTop: 8 } : {}}
            header={
              <Flex gap={8}>
                <IconSvg icon={'IcoREnvelope'} />
                {primaryEmail?.address}
              </Flex>
            }
          >
            <div style={{ display: 'flex', gap: 8, flexDirection: 'column' }}>
              <span className={s.panelHeading}>Secondaries</span>
              <Space direction={'vertical'}>
                {!!secondaryEmails?.length ? (
                  secondaryEmails.map(({ email }) => <div key={email.id}>{email.address}</div>)
                ) : (
                  <Button size={'small'} icon={'IcoRPlus'} onClick={onSettingsClick}>
                    Add
                  </Button>
                )}
              </Space>
            </div>
          </Collapse.Panel>
        </Collapse>

        <div className={s.added}>Member since: {moment(dateAdded).format('MMMM D, yyyy')}</div>
      </Space>
      {showButton && (
        <Button size={'large'} icon={'IcoRGear'} modification={'primary'} onClick={onSettingsClick}>
          Settings
        </Button>
      )}
    </div>
  );
};
