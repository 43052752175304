/**
 * Download icon.svg (from design) -> move to 'src/media/assets/icons/svg/' -> use 'yarn icons'
 */

import * as iconsSvg from '@/media/assets/icons';
import cn from 'classnames';
import { identity, pickBy } from 'lodash';
import { ReactNode } from 'react';

// Styles
import s from './IconSvg.module.less';

export type IIconSVG = keyof typeof iconsSvg

export type IIconSVGProps = {
  icon: IIconSVG;
  color?: string;
  size?: number
  className?: string;
  iconClassName?: string;
  prefix?: ReactNode;
  suffix?: ReactNode;
}

export const IconSvg = ({ className, icon, color, size, prefix, suffix, iconClassName }: IIconSVGProps) => {
  const Icon = iconsSvg[ icon ];
  const styles = pickBy({
    color: color, width: size, height: size,
  }, identity);

  return (
    <span className={cn(s.wrapper, className)}>
      {prefix}<Icon data-icon={icon} className={cn(s.icon, iconClassName)} style={styles} />{suffix}
    </span>
  );
};