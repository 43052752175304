import { Button } from '@/components/common/button/Button';
import { IconSvg, IIconSVG } from '@/components/common/icons/IconSvg';
import { AppLink } from '@/components/common/link/AppLink';
import { Drawer } from 'antd';
import cn from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import s from './HeaderMobileMenu.module.less';

type HeaderMobileMenuProps = {
  ticketsCount?: number;
};

export const HeaderMobileMenu = ({ ticketsCount }: HeaderMobileMenuProps) => {
  const { t } = useTranslation();
  const [drawerVisible, setDrawerVisible] = useState(false);

  const menuItems: {
    link: string;
    icon: IIconSVG;
    name: string;
    count?: number;
  }[] = [
    {
      link: '/orders',
      icon: 'IcoRFileUser',
      name: 'Orders',
    },
    {
      link: '/tickets',
      icon: 'IcoRTicket',
      name: 'Tickets',
      count: ticketsCount,
    },
    {
      link: '/profile',
      icon: 'IcoUserOutlined',
      name: 'Profile',
    },
  ];

  return (
    <>
      <Button
        className={cn(s.burger, { [s.burgerMark]: !!ticketsCount })}
        onClick={() => setDrawerVisible(true)}
        icon="IcoRBars"
        modification="text"
      />
      <Drawer
        width={300}
        placement="right"
        visible={drawerVisible}
        title="Menu"
        onClose={() => setDrawerVisible(false)}
        className={s.drawer}
      >
        <AppLink to={'/order'} className={s.orderBtn} onClick={() => setDrawerVisible(false)}>
          <Button size="large" icon={'IcoRPlus'} modification="primary">
            {t('header.orderBtn')}
          </Button>
        </AppLink>

        <ul className={s.menu}>
          {menuItems.map(({ link, icon, name, count }) => (
            <li key={link + name} className={s.menuItem}>
              <AppLink
                key={link}
                to={link}
                className={s.menuLink}
                activeClassName={s.menuLinkActive}
                onClick={() => setDrawerVisible(false)}
              >
                <IconSvg icon={icon} />
                <span className={s.menuLinkName}>{name}</span>
                {count && <span className={s.menuLinkCounter}>{count}</span>}
              </AppLink>
            </li>
          ))}
        </ul>
      </Drawer>
    </>
  );
};
