import { IWriter } from '@/shared/types/participants';
import { IDs, ILogMessage, Patch } from '@/types/common/api';
import { AssociationCustomerFileTicket, AssociationUserFileTicket, AssociationWriterFileTicket } from '@/types/file/file';
import { IOrder, IOrderSnapshot } from '@/types/order';
import { IRevision } from '@/types/revision';
import { AscDesc } from '@/types/search';

export type TicketCreateContexts = 'Profile' | 'Order' | 'Revision' | 'Ticket';

export type TicketActions = 'resolve' | 'withdraw';

export enum ITicketStatuses {
  Draft = 1,
  Pending,
  InWork,
  Resolved,
  Canceled,
  Removed,
  Withdrawn,
}

export enum ITicketOrigins {
  User = 1,
  Customer,
  Writer,
}

//  todo: temporary type
export type ITicketSources = '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8';

export type ITicket = {
  id: number;
  dateAdded: string;
  dateDue?: string;
  name: string;
  description?: string;
  idStatus: ITicketStatuses;
  idOrigin: ITicketOrigins;
  idSource?: ITicketSources;
};

export type ITicketSearchItem = ITicket & {
  parentTicket?: ITicket;
  order?: IOrder;
  orderSnapshot?: IOrderSnapshot;
  revision?: IRevision;
};

export enum ETicketVisibilities {
  User = 1,
  Customer,
  Writer,
  Everyone,
}

export type ITicketDetails = ITicketSearchItem & {
  writer?: IWriter;
  ownTickets: ITicket[];
  idVisibility: ETicketVisibilities;
  cAssociationWriterFiles: AssociationWriterFileTicket[];
  cAssociationUserFiles: AssociationUserFileTicket[];
  cAssociationCustomerFiles: AssociationCustomerFileTicket[];
};

export type ITicketSearchParams = {
  dateAddedFrom?: string;
  dateAddedTo?: string;
  dateDueFrom?: string;
  dateDueTo?: string;
  name?: string;
  idOrder?: number[];
  idOrderSnapshot?: number[];
  idRevision?: number[];
  idParentTicket?: number[];
  idStatus?: ITicketStatuses[];
  sortType?: AscDesc;
  sortBy?: ITicketSortFields;
  page?: number;
  size?: number;
};

export type ITicketSortFields = 'id' | 'dateAdded' | 'name' | 'idStatus';

export type ITicketDateFields = ['dateAdded', 'dateDue'];

export type ITicketCreatePayload = {
  name: string;
  description?: string;
  dateDue?: string;

  // extra
  idFiles?: number[];
};

export type ITicketCreateRequest = Patch<ITicketCreatePayload> & { context: TicketCreateContexts };

export type ITicketUpdatePayload = ILogMessage & {
  name?: string;
  description?: string;
};

export type ITicketUpdateRequest = Patch<ITicketUpdatePayload>;

export type IPatchTicketPayload = ILogMessage;

export type ITicketStatusPayload = ILogMessage;

export type ITicketStatusRequest = Patch<ILogMessage>;

export type ITicketFilesPayload = IDs;

export type ITicketFilesRequest = Patch<ITicketFilesPayload>;
