import { useUpdateProfilePreferencesMutation } from '@/api/profile';
import { getPreferencesWithoutPages } from '@/helpers/preferences';
import { useAppSelector } from '@/hooks/redux';
import { preferencesSelector } from '@/store/preferences/preferencesSlice';
import { ProfilePreferences } from '@/types/profile';
import { isEqual } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';

// 2 minutes
const BACKEND_UPDATE_INTERVAL = 2 * 60 * 1000;

export const usePreferences = (initialized: boolean, profilePreferences?: ProfilePreferences) => {
  const [initialPreferencesSet, setInitialPreferencesSet] = useState(false);
  const [requireUpdate, setRequireUpdate] = useState(false);
  const [localPreferences, setLocalPreferences] = useState<ProfilePreferences>();
  const [updatePreferences] = useUpdateProfilePreferencesMutation();
  const currentPreferences = useAppSelector(preferencesSelector);

  const requireUpdateRef = useRef(requireUpdate);
  requireUpdateRef.current = requireUpdate;
  const currentPreferencesRef = useRef<ProfilePreferences>(currentPreferences);
  currentPreferencesRef.current = currentPreferences;

  // watch user preferences change
  useEffect(() => {
    if (!initialized) return;
    setInitialPreferencesSet(true);
    setLocalPreferences(profilePreferences);
  }, [profilePreferences, initialized]);

  // Watch search preferences changes
  useEffect(() => {
    // if not fetched data from /profile endpoint do nothing
    if (!initialized || !localPreferences) return;

    // check if preferences are different from current
    const updateCondition = !isEqual(getPreferencesWithoutPages(localPreferences), getPreferencesWithoutPages(currentPreferences));

    if (updateCondition) setRequireUpdate(true);
  }, [currentPreferences, updatePreferences, profilePreferences, localPreferences, initialized]);

  const syncPreferences = useCallback(() => {
    if (requireUpdateRef.current) {
      setLocalPreferences(currentPreferencesRef.current);
      updatePreferences({
        preferences: getPreferencesWithoutPages(currentPreferencesRef.current),
      });
      setRequireUpdate(false);
    }
  }, [currentPreferencesRef, requireUpdateRef, updatePreferences]);

  // update backend
  useEffect(() => {
    const timer = setInterval(syncPreferences, BACKEND_UPDATE_INTERVAL);
    return () => {
      clearInterval(timer);
    };
  }, [syncPreferences]);

  return {
    initialPreferencesSet,
  };
};
