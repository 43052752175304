type FeatureItem<T> = {
  type: T;
  title: string;
  path: `/${string}`;
};

type Features = Record<string, FeatureItem<string>>;

type Validate<T extends Features> = {
  [FeatureType in keyof T]: FeatureType extends T[FeatureType]['type'] ? T[FeatureType] : T[FeatureType] & { type: never };
};

const featureTypeValidator = <
  FeatureType extends string,
  Feature extends FeatureItem<FeatureType>,
  Data extends Record<FeatureType, Feature>,
>(
  data: Validate<Data>,
) => data;

export const FEATURE = featureTypeValidator({
  Dicts: {
    title: 'Dicts',
    path: '/dicts',
    type: 'Dicts',
  },
  SiteSettings: {
    title: 'SiteSettings',
    path: '/site-settings',
    type: 'SiteSettings',
  },
  Auth: {
    title: 'Auth',
    path: '/auth',
    type: 'Auth',
  },
  OrderForm: {
    title: 'Order Form',
    path: '/orderform',
    type: 'OrderForm',
  },
  PriceQuote: {
    title: 'Price Quote',
    path: '/price-quote',
    type: 'PriceQuote',
  },
  Order: {
    title: 'Order',
    path: '/orders',
    type: 'Order',
  },
  OrderSnapshot: {
    title: 'Order Snapshot',
    path: '/order-snapshots',
    type: 'OrderSnapshot'
  },
  Profile: {
    title: 'Profile',
    path: '/profile',
    type: 'Profile',
  },
  OwnFile: {
    title: 'OwnFile',
    path: '/own-files',
    type: 'OwnFile',
  },
  Revision: {
    title: 'Revision',
    path: '/revisions',
    type: 'Revision',
  },
  Ticket: {
    title: 'Ticket',
    path: '/tickets',
    type: 'Ticket',
  },
});

export const FEATURE_LIST = Object.keys(FEATURE);
export type FeatureType = typeof FEATURE_LIST[number];

export const getURL = (feature: FeatureType, suffix?: string | number): string => {
  const _feature = FEATURE[feature];
  if (!_feature) return 'n\\a';
  const { path } = _feature;
  return `${path}${suffix ? `/${suffix}` : ''}`;
};
